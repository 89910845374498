<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      <a href="https://defido.com" target="_blank" rel="noopener">$BASE</a> & <a href="https://defido.com" target="_blank" rel="noopener">defido.com</a> & <a href="https://defido.com" target="_blank" rel="noopener">defido.chat</a> are the ONLY official links!
    </p>
    <h3>Official Chat links</h3>
    <div class="buttons">
    <a href="https://t.me/DefidoCoin" target="_blank" rel="noopener" class="btnOne"><button class="btnLink btnText btnOne">Telegram</button></a>
    <a href="https://discord.gg/GKTu97De5J" target="_blank" rel="noopener" class="btnTwo"><button class="btnLink btnText">Discord</button></a>
    </div>

    <p>@defido on telegram & twitter.</p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
buttons {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.buttons {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.btnLink {
  background: #fee500;
  border: none;
  cursor: pointer;
  display: inline-block;
}

.btnText {
  color: #000;
  font-style: bold;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 15px;
  padding: 20px;
}

.btnOne {
  justify-self: end;
}

.btnTwo {
  justify-self: start;
}

</style>
